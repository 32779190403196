import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import React, {useState} from "react"
import Link from "next/link"
import {tabItems} from "../../../constants/Instances"
import Modal from "../../reusable/components/Modal/Modal"
import DynamicFormGenerator from "../../forms/DynamicFormGenerator"
import {useTranslation} from "next-i18next"

const MobileEcosystemTabs = () => {
	const [activeItem, setActiveItem] = useState("MySofaPlan")
	const [isModalShown, setIsModalShown] = useState(false)
	const {t} = useTranslation()
	return (
		<>
			<div className="my-4" style={{border: "1px solid #DEE2E6"}}/>
			<div className="d-flex align-items-center justify-content-between mb-4 ">
				<Typography variant="bodySm" semanticTag="span">MyPlan ecosystem</Typography>
			</div>
			<ul className="list-unstyled">
				{tabItems.map((item, index) => item.ComingSoon ? (
					<li
						key={index}
						className="p-3 mb-3 cursor-pointer"
						style={{backgroundColor: item.bgColor, borderRadius: 16}}
						onClick={() => setIsModalShown(true)}>
						<a
							className="text-decoration-none d-flex justify-content-between align-items-center"
							style={{color: "inherit", opacity: 0.4}}
							rel="noreferrer"
						>
							<div className="d-flex align-items-center" style={{columnGap: 16}}>
								<Image src={item.mobileIconSrc} width="24px" height="24px" alt="icon" />
								<div style={{paddingTop: "0.4rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start"}}>
									<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
										<Image src={item.iconSrc} width="120px" height="19px" alt={item.title} />
									</div>
									<Typography variant="bodyXSm" semanticTag="span">Coming soon</Typography></div>

							</div>
							<Image src={item.checkmarkSrc} width="24px" height="24px" alt="Mail-add" style={{opacity: 1, zIndex: 99999}} />
						</a>
						{isModalShown &&
								<Modal isOpen={isModalShown} closeOnTouch={true} closeModal={() => setIsModalShown(prev => !prev)}>
									<div className="slide-up" style={{position: "absolute", bottom: 0, left: 0, right: 0, background: "white", height: "29rem", borderTopLeftRadius: "2rem", borderTopRightRadius: "2rem", zIndex: 9999999}}>
										<div className="row mb-3 mt-1 justify-content-between align-items-center ">
											<div className="col-11 cursor-pointer text-end mt-1" onClick={() => setIsModalShown(prev => !prev)}>
												<Image src="/assets/icons/close-icon.svg" width="20" height="20"/>
											</div>
										</div>
										<div className="d-flex justify-content-start align-items-center flex-column">
											<Typography className="col-11 mb-3" variant="heading1Black" style={{lineHeight: "28px"}}>{item.title} - <span style={{color: item.primaryColor}}> Coming soon.</span></Typography>
											<Typography className="col-11 mb-3" variant="bodyXSm" style={{lineHeight: "16px"}}>{item.description}</Typography>
											<DynamicFormGenerator formData={{...item.newsletterFormData, className: "row mx-2 mb-5 mt-4"}} t={t} theme={item.primaryColor}/>
										</div>
									</div>
								</Modal>}
					</li>

				) : (
					<li
						key={index}
						className="p-3 mb-3 cursor-pointer"
						style={{backgroundColor: item.bgColor, borderRadius: 16}}
						onClick={() => setActiveItem(item.title)}>
						<Link href={item.href}>
							<a
								href={item.href}
								className="text-decoration-none d-flex justify-content-between align-items-center"
								style={{color: "inherit"}}
								rel="noreferrer"
							>
								<div className="d-flex align-items-center" style={{columnGap: 16}}>
									<Image src={item.mobileIconSrc} width="24px" height="24px" alt="icon" />
									<div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "0.4rem"}}>
										<Image src={item.iconSrc} width="120px" height="19px" alt={item.title} />
									</div>
								</div>
								{item.title === activeItem && <Image src={item.checkmarkSrc} width="24px" height="24px" alt="checkmark" />}
							</a>
						</Link>
					</li>

				))}

			</ul>
		</>
	)
}

export default MobileEcosystemTabs

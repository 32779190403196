import React, {useContext} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import styles from "./navigation.module.scss"
import {useRouter} from "next/router"
import WishListIndicator from "../reusable/WishListIndicator"

type Props = {
    isFrontpage?: boolean
    mobile?: boolean
}

const WishListButton: React.FC<Props> = ({mobile}) => {
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)
	const router = useRouter()
	const active = router.asPath === t("/wish-list")
	return (
		<Link href={"/wish-list"} as={t("/wish-list")}>
			<a className={` position-relative ${mobile ? `btn shadow-none ${styles.ctaGreyHover}` : ""} ${styles.wishListIconBg} ${active ? styles.wishList_active : ""}`} onClick={() => {
				reactGA?.event({
					category: gaCategories.wishList,
					action: gaEvents.wishListVisited,
					label: gaEvents.wishListVisited,
					nonInteraction: false
				})
			}}>
				<img src={"/assets/icons/Heart.svg"} alt="Wish list icon" />
				<WishListIndicator />
			</a>
		</Link>
	)
}

WishListButton.propTypes = {
	isFrontpage: PropTypes.bool,
	mobile: PropTypes.bool
}

export default WishListButton

import React, {useState} from "react"
import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import AnimationComponent from "../../reusable/components/AnimationComponent/AnimationComponent"

const BFESubHeader = () => {
	const [isModalShown, setIsModalShown] = useState(false)
	return (
		<>
			<div className="d-none d-md-block">
				<div className="bfe-subheader position-relative">
					<Image src={"/assets/icons/BFE-logo.svg"} width={125} height={28} alt="Brands For Employees"/>
					<Typography variant="heading3MdBodyLgBold" semanticTag="span" style={{color: "#6C7074"}}>&</Typography>
					<div className="d-flex gap-2">
						<Image src={"/assets/MySofaPlan.ch.svg"} width={144} height={26} alt="MySofaPlan.ch"/>
						<Image onClick={() => setIsModalShown(prev => !prev)} src={"/assets/icons/question.svg"} width={16} height={16} alt="Question Mark" className="cursor-pointer"/>
					</div>
				</div>
				{isModalShown &&
				<div className="bfe-modal position-absolute d-flex justify-content-center">
					<div className="bfe-modal-content position-relative">
						<Typography variant="heading3MdBodyLgBold" semanticTag="h3">Dein Zuhause. Einzigartig für Dich.</Typography>
						<Typography variant="bodySm" semanticTag="p">{"MySofaPlan ist der Ort für alle, die bei der nächsten \"Zügleten\" kein Loch im Portemonnaie haben wollen. Stöbere in unserem Sortiment und richte Dein Zuhause mit zinsfreien Monatsraten und bis zu 20 % Rabatt ein - jetzt exklusiv für \"Brands for Employees\"-Nutzer*innen!"}</Typography>
						<Button label={"Jetzt stöbern"} onClick={() => setIsModalShown(false)} textColor="#fff" bgColor="#6E9060"/>
						<div className="arrow-up-white">
							<Image src="/assets/icons/arrow-up-white.svg" width={16} height={8} alt="arrow"/>
						</div>
					</div>
				</div>
				}
			</div>

			<div className="d-md-none">
				<div className="bfe-subheader position-relative">
					<div className="d-flex justify-content-end col-10 bfe-header-image">
						<Image src={"/assets/icons/BFE-logo.svg"} width={89} height={20} alt="Brands For Employees"/>
						<Typography variant="bodySmBold" semanticTag="span" style={{color: "#6C7074"}}>&</Typography>
						<Image src={"/assets/MySofaPlan.ch.svg"} width={112} height={14} alt="MySofaPlan.ch"/>
					</div>
					<div className="d-flex justify-content-end">
						<Image onClick={() => setIsModalShown(prev => !prev)} src={"/assets/icons/question.svg"} width={20} height={20} alt="Question Mark" className="cursor-pointer"/>
					</div>
				</div>
			</div>
			<>
				<div className="d-block d-md-none" style={{zIndex: 20010}}>
					<div className={`position-fixed top-0 start-0 bottom-0 end-0 bg-light-dark-transparent ${!isModalShown && "d-none"}`} style={{
						zIndex: 20002
					}} onClick={() => setIsModalShown(!isModalShown)}/>
					<AnimationComponent type="slideUp" inProp={isModalShown}>
						<div className={"position-fixed px-3 py-4 bg-white start-0 bottom-0 end-0"} style={{
							zIndex: 999999,
							borderRadius: "16px 16px 0px 0px",
							border: "1px solid #f4f4f4"
						}}>
							<div className="row mb-3  justify-content-between align-items-center">
								<div className="col-12 cursor-pointer text-end" onClick={() => setIsModalShown(!isModalShown)}>
									<Image src="/assets/icons/close-icon.svg" width="20" height="20"/>
								</div>
							</div>
							<Typography className="col-12 mb-3" variant="heading1Black" style={{lineHeight: "36px"}}>Dein Zuhause. Einzigartig für Dich.</Typography>
							<Typography className="col-12" variant="bodyLg">{"MySofaPlan ist der Ort für alle, die bei der nächsten \"Zügleten\" kein Loch im Portemonnaie haben wollen. Stöbere in unserem Sortiment und richte Dein Zuhause mit zinsfreien Monatsraten und bis zu 20 % Rabatt ein - jetzt exklusiv für \"Brands for Employees\"-Nutzer*innen!"}</Typography>
							<div className="pt-4 mt-3">
								<Button label={"Jetzt stöbern"} style={{height: 40}} fontSizeSmall onClick={() => setIsModalShown(false)} className="col-12" textColor="#fff" bgColor="#6E9060" />
							</div>
						</div>

					</AnimationComponent>
				</div>
			</>
		</>
	)
}

export default BFESubHeader

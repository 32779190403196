/* eslint-disable complexity */
import React, {useContext, useState} from "react"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import Button from "../../reusable/components/Button"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"
import Link from "next/link"
import TopCategoriesMegaMenu from "../TopCategoriesMegaMenu"
import styles from "../navigation.module.scss"
import DataContext from "../../../context/DataContext"
import {useRouter} from "next/router"
import {filterUrl} from "../../filter/FilterUrl"
import {colors} from "../../../colors/Colors"
import LinkWithIcon from "../../reusable/components/LinkWithIcon"
import Image from "next/image"
import {CollectionName} from "../../../constants"
import urlParams, {getUrlParamByAttributeName} from "../../../utility/UrlParams"
import {generateUUID} from "../../../utility/UUID"
import FilterDropdownItem from "../../filter/dropdown/FilterDropdownItem"
import slugify from "slugify"
import {getMyBikePlanUrl, otherInstancesCategoryTitles} from "../../../constants/Instances"
import AccordionAnimation from "../../reusable/components/AnimationComponent/AccordionAnimation"
import {topCategoriesMegaMenuMHP} from "../../../constants/TopCategories"
import OrganizationContext from "../../../context/OrganizationContext"

type Props = {
	toggleMenu?: () => void
	item?: any,
	megaMenuTopCategories?:any[]
	heights?:{title:string, slug:string}[]
	otherInstancesBrandsForMegaMenu?:any[]
	themeColor?: string
	setIsMobileMenuVisible?: any
}

const MHPBrandsArr = [
	{
		menuLabel: "Kave home",
		filterSlug: "kave-home"
	},
	{
		menuLabel: "Bolia",
		filterSlug: "bolia"
	},
	{
		menuLabel: "Innovation Living",
		filterSlug: "innovation-living"
	},
	{
		menuLabel: "Furninova",
		filterSlug: "furninova"
	},
	{
		menuLabel: "Conform",
		filterSlug: "conform"
	}
]

const addOnsArr = [
	{
		label: "Arm chair",
		filterSlug: "sessel"
	},
	{
		label: "Chairs",
		filterSlug: "stuhle"
	},
	{
		label: "Deko",
		filterSlug: "deko"
	},
	{
		label: "Light",
		filterSlug: "lampen"
	},
	{
		label: "Small Shelf Add On",
		filterSlug: "kleine-regale"
	},
	{
		label: "Small Table Add On",
		filterSlug: "kleine-tische"
	}
]
const sofaSubCategories = [
	{
		label: "2 Seater",
		filterSlug: "zweisitzer"
	},
	{
		label: "3 Seater",
		filterSlug: "dreisitzer"
	},
	{
		label: "4 Seater",
		filterSlug: "viersitzer"
	},
	{
		label: "6 seater",
		filterSlug: "sechssitzer"
	},
	{
		label: "L Seater",
		filterSlug: "grosse-sofas"
	},
	{
		label: "Outdoor",
		filterSlug: "balkon-and-garten"
	}
]

const tableSubCategories = [
	{
		label: "Coffee Table",
		filterSlug: "couchtisch"
	},
	{
		label: "Desk",
		filterSlug: "schreibtische"
	},
	{
		label: "Dinning Table",
		filterSlug: "dinning_table"
	},
	{
		label: "Round Table",
		filterSlug: "round_table"
	}
]

const bedSubCategories = [
	{
		label: "Bed Frame",
		filterSlug: "bettrahmen"
	},
	{
		label: "Mattress",
		filterSlug: "matratze"
	}
]

const topCategoriesMegaMenuMBP = [
	{
		title: "E-Bikes",
		buttonTitle: "Alle Modelle",
		link: "e-bike",
		slugName: "",
		subcategories: [
			{
				title: "E-Mountain",
				slugName: "e-mountain",
				link: "mountain-e-bike",
				buttonTitle: "Alle Mountain E-Bikes"
			},
			{
				title: "E-City",
				slugName: "e-urban",
				link: "city-e-bike",
				buttonTitle: "Alle City E-Bikes"
			},
			{
				title: "E-Trekking",
				slugName: "e-trekking",
				link: "trekking-e-bike",
				buttonTitle: "Alle Trekking E-Bikes"
			},
			{
				title: "45 Km/h",
				slugName: "45-km",
				link: "45-km",
				buttonTitle: "All 45km/h E-Bikes"
			},
			{
				title: "Cargo E-Bikes",
				slugName: "cargo-e-bikes",
				link: "cargo-e-bikes",
				buttonTitle: "Cargo-E-Bikes"
			}
		]
	},
	{
		title: "Bikes",
		buttonTitle: "Alle Bikes",
		link: "normale-bikes",
		slugName: "",
		subcategories: [
			{
				title: "Road Bikes",
				slugName: "rennvelos",
				link: "rennvelos",
				buttonTitle: "Alle Road Bikes"
			},
			{
				title: "Gravel Bikes",
				slugName: "gravelbikes",
				link: "gravelbikes",
				buttonTitle: "Alle Gravel Bikes"
			},
			{
				title: "Fully Bikes",
				slugName: "fully-non-electric",
				link: "fully-non-electric",
				buttonTitle: "Alle Fully Bikes"
			}
		]
	},
	{
		title: "Accessories",
		buttonTitle: "Alle Accessories",
		slugName: "",
		link: "",
		subcategories: []
	}
]

const MBPAccessoriesBrands = [
	{name: "Thule", slug: "thule"},
	{name: "Croozer", slug: "croozer"}
]

const NewMegaMenu: React.FC<Props> = ({toggleMenu, item = "", setIsMobileMenuVisible, megaMenuTopCategories, otherInstancesBrandsForMegaMenu, themeColor, heights}) => {
	const {t} = useTranslation("common")
	const [loading, setLoading] = useState(false)
	const activeItemTitle = item.title === "E-Bikes" ? "Mountain E-Bikes" : item.title === "Accessories" ? topCategoriesMegaMenuMHP[1].title : otherInstancesCategoryTitles.includes(item.title) ? topCategoriesMegaMenuMBP[0].title : topCategoriesMegaMenuMHP[0].title
	const [activeItemLink, setActiveItemLink] = useState("")
	const [activeItem, setActiveItem] = useState(activeItemTitle)
	const [isToggleOpen, setIsToggleOpen] = useState(true)
	const {organizationSlug} = useContext(OrganizationContext)
	const {brands, accessoryFilters, bikeFilters} = useContext(DataContext)
	const accessoriesTypes = accessoryFilters[0]
	const allBrands = bikeFilters.filter(filter => filter.name === "Marke")[0].options
	const router = useRouter()
	const tCommon = useTranslation().t
	const isMyHomePlan = process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan"
	const topBrandsForMegaMenu = 	isMyHomePlan ? item.title === "Alle Marken" ? allBrands : MHPBrandsArr : brands.filter(({fields}) => fields?.addToMegaMenu)
	const {primary} = colors

	const onBtnClick = () => {
		setLoading(true)
		toggleMenu()
	}

	const {reactGA} = useContext(AnalyticsContext)

	return (
		<>
			<div className="bg-white d-none d-md-block" onMouseLeave={toggleMenu} style={{zIndex: 9999,
				borderBottomLeftRadius: "32px",
				borderBottomRightRadius: "32px",
				borderTop: "1px solid #DEE2E6",
				maxWidth: 820}}>
				<div style={{padding: "24px 51px 24px 20px"}}>
					{item?.title !== "Alle Marken" &&
					<Button label={t(item?.buttonTitle) || item?.title}
						textColor={themeColor === primary || !themeColor ? "#fff" : "black"}
						color="#fff"
						bgColor={themeColor === primary || !themeColor ? primary : themeColor}
						outline={false}
						paddingY="2"
						href={
							item?.title === "Alle Furniture" ? filterUrl({}, null, null, tCommon) : otherInstancesCategoryTitles.includes(item.title) ? getMyBikePlanUrl(organizationSlug, "-", "-", "-") : filterUrl({[urlParams.category]: item.link}, null, null, tCommon)}
						className={"col-auto"}
						isLoading={loading} onClick={() => {
							onBtnClick()
							reactGA?.event({
								category: gaCategories.megaMenu,
								action: gaEvents.allProductsButton,
								label: gaEvents.allProductsButton,
								nonInteraction: false
							})
						}}/>}
					<div className="d-flex mt-4 justify-content-between" style={{columnGap: 16}}>

						{item.title === "All Furniture" || item.title === "Accessories" ?
							<div className="d-flex justify-content-between col-auto">
								<div className="col-auto pe-4" style={{borderRight: "1px solid #DEE2E6"}}>
									<TopCategoriesMegaMenu sectionWidth={240} megaMenuTopCategories={topCategoriesMegaMenuMHP} activeMainCategoryTitle={item.title} activeItem={activeItem} handleToggleOpen={setIsToggleOpen} handleActiveItemSelect={setActiveItem}/>
								</div>
								<AccordionAnimation
									inProp={isToggleOpen}
									duration={200}
									transTimingFn="ease-out"
									unmount
									customHeight={"100%"}>

									{activeItem && (
										<div className="px-4" style={{width: 183}}>
											<div className="list-unstyled">
												{topCategoriesMegaMenuMHP
													.filter(category => category.title === activeItem)
													.map(category =>
														category.subcategories?.map((cat, idx) => (
															<ul key={idx} className="list-unstyled mb-4">
																<Typography variant="bodySmBold" className="px-3" style={{marginBottom: 12}}>
																	{cat.name}
																</Typography>
																{cat.items.map((el, itemIdx) => (
																	<li key={itemIdx} className="d-block">
																		<Link href={filterUrl({[urlParams.category]: el.link}, null, null, t)}>
																			<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																				<Typography variant="bodySm">{el.title}</Typography>
																			</a>
																		</Link>
																	</li>
																))}
															</ul>
														)))}
											</div>
										</div>)}</AccordionAnimation></div> :
							otherInstancesCategoryTitles.includes(item.title) && item.title === "Alle Modelle" ?
								<div className="d-flex flex-column justify-content-start flex-col col-auto">
									<div className="d-flex justify-content-between col-auto">
										<div className="col-auto pe-4" style={{borderRight: "1px solid #DEE2E6", width: 270}}>
											<TopCategoriesMegaMenu sectionWidth={240} getOtherInstanceUrl={getMyBikePlanUrl} megaMenuTopCategories={topCategoriesMegaMenuMBP} hasItemArrow activeItem={activeItem} handleToggleOpen={setIsToggleOpen} handleActiveItemLinkSelect={setActiveItemLink} handleActiveItemSelect={setActiveItem}/>
										</div>
										<AccordionAnimation
											inProp={isToggleOpen}
											duration={200}
											transTimingFn="ease-out"
											unmount
											customHeight={"100%"}>
											{activeItem && activeItem !== "Accessories" && (
												<div className="px-4" style={{width: 183}}>
													<Typography variant="bodySmBold" className="px-3" style={{marginBottom: 12}}>{t("Categories")}</Typography>
													<div className="list-unstyled">
														{topCategoriesMegaMenuMBP.map(category => (
															category.title === activeItem && category.subcategories?.map((item, idx) => (
																<li key={idx} className="list-unstyled mb-0">
																	<Link href={getMyBikePlanUrl(organizationSlug, item.link, "-", "-", false)}>
																		<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																			<Typography variant="bodySm">{item.title}</Typography>
																		</a>
																	</Link>
																</li>
															))
														))}
													</div>
												</div>
											)}
										</AccordionAnimation>
									</div>
								</div>								:
								item.title === "Sofa" || item.title === "Table" || item.title === "Bed" ?
									<div style={{width: 183}}>
										<Typography variant="bodySmBold" className="px-3" style={{marginBottom: 12}}>{t("Categories")}</Typography>
										{(item.title === "Sofa" ? sofaSubCategories : item.title === "Table" ? tableSubCategories : item.title === "Bed" ? bedSubCategories : null)?.map(subCategoryItem =>
											<div key={generateUUID()} className="col-auto">
												<Link href={filterUrl({[urlParams.category]: [item.link, subCategoryItem.filterSlug]}, null, null, t)}>
													<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`} >
														<Typography variant={"bodySm"}>{t(subCategoryItem.label)}</Typography>
													</a>
												</Link>
											</div>
										)}
									</div> :
									item.title === "Accessories" || item.title === "Add ons" ?
										<div style={{width: 183}}>
											<Typography variant="bodySmBold" className="px-3" style={{marginBottom: 12}}>{t("Categories")}</Typography>

											{isMyHomePlan ?
												addOnsArr?.map(item =>
													<div key={generateUUID()} className="col-auto">
														<Link href={filterUrl({[urlParams.category]: [item.filterSlug]}, null, null, t)}>
															<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`} >
																<Typography variant={"bodySm"}>{t(item.label)}</Typography>
															</a>
														</Link>
													</div>
												)											:
												accessoriesTypes ? accessoriesTypes?.options.map(brand =>
													<div key={generateUUID()} className="col-auto">
														<FilterDropdownItem key={brand} label={brand} collectionName={CollectionName.Accessories}
															param={getUrlParamByAttributeName(accessoriesTypes.name, t)}
															value={slugify(brand).toLowerCase()} labelOnly styles={{padding: "6px 16px", marginBottom: "0px"}} className="height-link"/>
													</div>
												) : null}
										</div> : null
						}
						{item?.title && (
							<div className="col-auto">
								<div className="list-unstyled d-flex">
									{topCategoriesMegaMenuMHP.map(category => (
										category.title === item?.title && category.subcategories?.map((item, idx) => (
											<ul key={idx} className="list-unstyled mb-4" style={{minWidth: 183, width: "100%"}}>
												<Typography variant="bodySmBold" className="px-3" style={{marginBottom: 12}}>
													{item.name}
												</Typography>
												{item.items.map((el, itemIdx) => (
													<li key={itemIdx} className="d-block">
														<Link href={filterUrl({[urlParams.category]: el.link}, null, null, t)}>
															<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																<Typography variant="bodySm">{el.title}</Typography>
															</a>
														</Link>
													</li>
												))}
											</ul>
										))
									))}
								</div>
							</div>
						)}
						{otherInstancesCategoryTitles.includes(item.title) && heights && activeItem !== "Accessories" &&
							<div style={{width: 183}}>
								<Typography variant="bodySmBold" className="px-3" style={{marginBottom: 12}}>Körpergrösse</Typography>
								{
									heights?.map(height =>
										<div key={generateUUID()} className="col-auto">
											<Link href={getMyBikePlanUrl(organizationSlug, "-", height.slug, "-", false)}>
												<a target="_blank" className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`} >
													<Typography variant={"bodySm"}>{height.title}</Typography>
												</a>
											</Link>
										</div>
									)
								}
							</div>}
						<div className="col-auto" style={{width: 183}}>
							<Typography variant="bodySmBold" style={{marginBottom: 12}} className="px-3">{t("top-brands")}</Typography>
							{activeItem === "Accessories" ?
								<ul className="list-unstyled">
									{["Thoole", "Croozer"].map(brand =>
										<div key={generateUUID()} onClick={onBtnClick}>
											<Link href={getMyBikePlanUrl(organizationSlug, slugify(t(brand, {ns: "filters"})).toLowerCase(), "-", "-", true)}>
												<a className={`text-decoration-none rounded-3 text-dark height-link d-block ${styles.megamenuItem}`}>
													<Typography variant={`${router.query.type === slugify(brand).toLowerCase() ? "bodyLgBold" : "bodySm"}`}>{t(brand, {ns: "filters"})}</Typography>
												</a>
											</Link>
										</div>
									)}
								</ul>								:
								otherInstancesCategoryTitles.includes(item.title) ? <ul className="list-unstyled">
									{otherInstancesBrandsForMegaMenu?.map((brand, idx) => {
										return (
											<li key={idx} className="d-block">
												<Link href={getMyBikePlanUrl("-", "-", brand.slug)}>
													<a
														target="_blank"
														onClick={() => {
															reactGA?.event({
																category: gaCategories.megaMenu,
																action: gaEvents.topBrands,
																label: brand.menuLabel,
																nonInteraction: false
															})
														}} className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`} >
														<Typography variant={"bodySm"}>{brand.name}</Typography>
													</a>
												</Link>
											</li>)
									})}
								</ul> :
									<ul className="list-unstyled">
										{topBrandsForMegaMenu?.map((brand, idx) => {
											return (
												<li key={idx} className="d-block">
													<Link href={filterUrl({[urlParams.category]: item.link, [urlParams.brand]: item.title === "Alle Marken" ? slugify(brand).toLowerCase() : brand.filterSlug}, null, null, t)}>
														<a onClick={() => {
															reactGA?.event({
																category: gaCategories.megaMenu,
																action: gaEvents.topBrands,
																label: item.title === "Alle Marken" ? brand : brand.menuLabel,
																nonInteraction: false
															})
														}} className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`} >
															<Typography variant={`${router.query.slug === brand ? "bodyLgBold" : "bodySm"}`}>{item.title === "Alle Marken" ? brand : brand.menuLabel}</Typography>
														</a>
													</Link>
												</li>)
										})}
									</ul>}
							{item?.title !== "Alle Marken" &&
							<div onClick={() => {
								onBtnClick()
								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.allEBrandsButton,
									label: gaEvents.allEBrandsButton,
									nonInteraction: false
								})
							}}>
								<LinkWithIcon
									href={otherInstancesCategoryTitles.includes(item.title) ? "https://www.mybikeplan.ch/alle-marken" : filterUrl({[urlParams.category]: item.link}, null, null, t)}
									label={t("Top brands")}
									iconBgColor="#EBEBEB"
									iconMargin="ms-2"
									labelVariant={"bodySmBold"}
									padding={"5.5px 16px"}
									icon={
										<Image src="/assets/icons/chevron-dark-bg-light.svg" width={20} height={20} alt="chevron right"/>
									}/>
							</div>}
						</div>
					</div>
				</div>
			</div>
			{/*	Mobile */}
			<div className="bg-white d-md-none" onMouseLeave={toggleMenu}>
				<div>
					{item?.title !== "Alle Marken" &&
					<Button label={item?.buttonTitle ? t(item?.buttonTitle) : t(item?.title)}
						textColor="#fff"
						color="#fff"
						bgColor={primary}
						outline={false}
						paddingY="2"
						fontSizeSmall
						href={
							item?.title === "Alle Modelle" ? filterUrl({}, null, null, tCommon) : filterUrl({[urlParams.category]: item.link}, null, null, tCommon)}
						className={"w-100"}
						isLoading={loading} onClick={() => {
							onBtnClick()
							reactGA?.event({
								category: gaCategories.megaMenu,
								action: gaEvents.allProductsButton,
								label: gaEvents.allProductsButton,
								nonInteraction: false
							})
						}}/>}
					<div className={`d-flex mt-4 ${item.title === "Alle Marken" ? "" : "justify-content-between"} flex-wrap`} style={{rowGap: 8}}>
						{item.title === "All Furniture" || item.title === "Accessories" ?
							<div className="d-flex justify-content-between col-auto mb-3">
								<div className="col-auto pe-3" style={{borderRight: "1px solid #DEE2E6", maxWidth: 190, width: "100%"}}>
									<TopCategoriesMegaMenu megaMenuTopCategories={topCategoriesMegaMenuMHP} activeMainCategoryTitle={item.title} activeItem={activeItem} handleToggleOpen={setIsToggleOpen} handleActiveItemSelect={setActiveItem}/>
								</div>
								{activeItem && (
									<div className="px-3" style={{width: 183}}>
										<div className="list-unstyled">
											{topCategoriesMegaMenuMHP
												.filter(category => category.title === activeItem)
												.map(category =>
													category.subcategories?.map((cat, idx) => (
														<ul key={idx} className="list-unstyled mb-4">
															<Typography variant="bodySmBold" className="px-2" style={{marginBottom: 12}}>
																{cat.name}
															</Typography>
															{cat.items.map((el, itemIdx) => (
																<li key={itemIdx} className="d-block">
																	<Link href={filterUrl({[urlParams.category]: el.link}, null, null, t)}>
																		<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																			<Typography variant="bodySm">{el.title}</Typography>
																		</a>
																	</Link>
																</li>
															))}
														</ul>
													)
													))}
										</div>
									</div>
								)}
							</div> :
							otherInstancesCategoryTitles.includes(item.title) ?
								<TopCategoriesMegaMenu getOtherInstanceUrl={getMyBikePlanUrl} megaMenuTopCategories={megaMenuTopCategories} /> :
								<div className="col-auto">
									{topCategoriesMegaMenuMHP.map(category => (
										category.title === item.title && category.subcategories?.map((item, idx) => (
											<ul key={idx} className="list-unstyled mb-4">
												<Typography variant="bodySmBold" className="px-2" style={{marginBottom: 12}}>
													{item.name}
												</Typography>
												{item.items.map((el, itemIdx) => (
													<li key={itemIdx} className="d-block" onClick={() => setIsMobileMenuVisible(false)}>
														<Link href={filterUrl({[urlParams.category]: el.link}, null, null, t)}>
															<a className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`}>
																<Typography variant="bodySm">{el.title}</Typography>
															</a>
														</Link>
													</li>
												))}
											</ul>
										))
									))}
								</div>
						}
						<div style={{maxWidth: 156, width: "100%", height: "auto"}}>
							{(item?.title === "Alle Modelle" || item?.title === "E-Mountain") &&
								<div className="px-2 pb-3">
									<div style={{border: "1px solid #DEE2E6"}}/>
								</div>}
							{item?.title !== "Alle Marken" && <Typography variant="bodySmBold" style={{marginBottom: 12}} className="px-2">{t("top-brands")}</Typography>}
							<ul className="list-unstyled">
								{topBrandsForMegaMenu?.map((brand, idx) => {
									return (
										<li key={idx}>
											<Link href={filterUrl({[urlParams.brand]: item.title === "Alle Marken" ? slugify(brand).toLowerCase() : brand.filterSlug}, null, null, t)}>
												<a onClick={() => {
													reactGA?.event({
														category: gaCategories.megaMenu,
														action: gaEvents.topBrands,
														label: item.title === "Alle Marken" ? brand : brand.menuLabel,
														nonInteraction: false
													})
												}} className={`text-decoration-none rounded-3 text-dark d-inline-block ${styles.megamenuItem}`} >
													<Typography variant={`${router.query.slug === brand ? "bodyLgBold" : "bodySm"}`}>{item.title === "Alle Marken" ? brand : brand.menuLabel}</Typography>
												</a>
											</Link>
										</li>)
								})}
							</ul>

							{item?.title !== "Accessories" && item?.title !== "Alle Marken" &&
								<div onClick={() => {
									onBtnClick()
									reactGA?.event({
										category: gaCategories.megaMenu,
										action: gaEvents.allEBrandsButton,
										label: gaEvents.allEBrandsButton,
										nonInteraction: false
									})
								}}>
									<LinkWithIcon
										href={filterUrl({[urlParams.category]: item.link}, null, null, t)}
										label={t("top-brands")}
										iconBgColor="#EBEBEB"
										iconMargin="ms-2"
										labelVariant={"bodySmBold"}
										padding={"5.5px 8px"}
										icon={
											<Image src="/assets/icons/chevron-dark-bg-light.svg" width={20} height={20} alt="chevron right"/>
										}/>
								</div>}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default NewMegaMenu

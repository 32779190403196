import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import Link from "next/link"
import {useTranslation} from "next-i18next"
import {useRouter} from "next/router"
import Typography, {Variants} from "../../reusable/Typography/Typography"
import {ChevronDown} from "react-bootstrap-icons"

type Props = {
	label: string
	href: string
	classGroup?: string,
	className?: string,
	align?: string
	action?: () => void
	isNoUppercase?: boolean
	typographyVariant?: keyof typeof Variants
	isChevronIcon?: boolean
	isItemOpen?: boolean
	onMouseLeave?: () => void
	activeItem?: string
	itemTitle?: any
	hasNoMargin?: boolean
	icon?: React.ReactNode
}

const NavItem = ({
	label,
	href,
	classGroup,
	className,
	align = "start",
	action,
	onMouseLeave,
	activeItem,
	itemTitle = "",
	isNoUppercase,
	typographyVariant,
	isChevronIcon,
	isItemOpen,
	hasNoMargin,
	icon
}: Props): React.ReactElement => {
	const {t} = useTranslation()
	const router = useRouter()
	const [styles, setStyles] = useState("")
	useEffect(() => {
		setStyles(`text-bold col-auto ${hasNoMargin ? "" : align === "start" ? "me-4" : "ms-5"}  ${router.asPath === href ? "nav-link-active" : ""} ${itemTitle === activeItem && "nav-link-active"} ${classGroup} ${className ? className : ""}`)
	}, [])
	return (
		<li style={{zIndex: 13000, height: "100%", padding: "8px 12px 0px"}} onMouseEnter={action} onMouseLeave={onMouseLeave} className={styles}>
			{icon && <div className="me-2 d-flex align-items-center" style={{height: 20}}>{icon}</div>}
			<Link href={href} as={t(href)}>
				<a className={`${isNoUppercase ? "" : "text-uppercase"} d-flex align-items-start text-dark text-decoration-none`}>
					<Typography variant={typographyVariant ? typographyVariant : "bodyLgBold"} semanticTag="span">
						{t(label)}
					</Typography>
				</a>
			</Link>
			{isChevronIcon && activeItem === itemTitle ? (
				<ChevronDown
					className="ms-2 mt-1"
					style={{transform: "rotate(180deg)", transition: "250ms", marginTop: 2}}
				/>
			) : (
				isChevronIcon ? <ChevronDown className="ms-2" style={{transition: "250ms", marginTop: 2}}/> : null
			)}
		</li>
	)
}

NavItem.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	classGroup: PropTypes.string,
	className: PropTypes.string,
	align: PropTypes.string
}

export default NavItem

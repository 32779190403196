import React, {Dispatch, SetStateAction, useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import {MenuTitle} from "../NavBar"
import Link from "next/link"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {colors} from "../../../colors/Colors"
import Image from "next/image"
import BookConsultation from "../../bikeDetails/BookConsultation"
import EbikesMenu from "./EbikesMenu"
import InfocenterMenu from "./InfocenterMenu"
import MobileEcosystemTabs from "./MobileEcosystemTabs"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"

type Props = {
	secondLineMenu: MenuTitle[]
	setIsMenuVisible?: Dispatch<SetStateAction<boolean>>
}

enum megaMenuItemName {
	konzept = "Konzept"
}

const slugsList = [
	{
		title: "All Furniture",
		buttonTitle: "All Furniture"
	},
	{
		title: "Sofas",
		slugName: "sofas",
		buttonTitle: "All Sofas",
		link: "sofas",
		icon: "/assets/icons/sofa.svg"
	},
	{
		title: "Accessories",
		slugName: "Accessories",
		link: "Accessories",
		buttonTitle: "Alle Accessories",
		icon: "/assets/icons/lamp.svg"
	},
	{
		title: "Alle Marken",
		link: null,
		buttonTitle: "Alle Marken"
	}
]

const NewMobileMegaMenu = ({secondLineMenu, setIsMenuVisible}: Props): React.ReactElement => {
	const {t} = useTranslation("common")
	const {reactGA} = useContext(AnalyticsContext)
	const [isEbikesMenuVisible, setIsEbikesMenuVisible] = useState(false)
	const [isInfocenterMenuVisible, setIsInfocenterMenuVisible] = useState(false)
	const [loading] = useState(false)
	const {primary} = colors

	const [activeCategory, setActiveCategory] = useState(null)

	const handleMenuItemClick = item => {
		setActiveCategory(item)
		setIsEbikesMenuVisible(true)

		if (item.title === "Alle E-Bikes") {
			reactGA?.event({
				category: gaCategories.megaMenu,
				action: gaEvents.allProductsButton,
				label: gaEvents.allProductsButton,
				nonInteraction: false
			})
		}
	}

	return (
		<div
			className={"w-100 vh-100 bg-white pt-4 w-100 overflow-auto"}
			onClick={e => {
				e.stopPropagation()
			}}>
			<nav style={{
				paddingBottom: 250
			}} className={"overflow-auto"}>
				{loading && <div className={"col-auto me-2 mx-auto"}>
					<div className={"spinner-border spinner-border-sm"} style={{color: primary}} role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>}
				<EbikesMenu isEbikesMenuVisible={isEbikesMenuVisible} setIsEbikesMenuVisible={setIsEbikesMenuVisible} setIsMenuVisible={setIsMenuVisible} activeCategory={activeCategory}/>
				<InfocenterMenu setIsInfocenterMenuVisible={setIsInfocenterMenuVisible} isInfocenterMenuVisible={isInfocenterMenuVisible} menuItems={secondLineMenu[0].items}/>

				<div className="d-flex flex-column pt-2 mt-1 px-2">
					<div className="border-bottom pb-4">
						{slugsList.map((item, i) =>
							<div key={i} className="d-flex justify-content-between align-items-center px-2 cursor-pointer height-link" onClick={() => handleMenuItemClick(item)}>
								<div className="d-flex align-items-center py-2">
									{item.icon &&
										<Image	src={item.icon} width={20} height={20} alt="electric"/>}
									<Typography variant="bodyLgBold" semanticTag="span" className={`${item.title !== "Alle E-Bikes" && "ms-3"}`}>{t(item.title)}</Typography>
								</div>
								<Image	src={"/assets/icons/chevron-right.svg"} width={20} height={20} alt="chevron right"/>
							</div>
						)}
						<Link href={filterUrl({[urlParams.category]: "sofa-set"}, null, null, t)}>
							<div className="d-flex align-items-center py-2 cursor-pointer height-link">
								<Typography variant="bodyLgBold" semanticTag="span" className={"ms-4"}>{t("Bundles")}</Typography>
							</div>
						</Link>
					</div>
				</div>

				<div className="links-section pt-4 px-3">
					<div className="d-flex justify-content-between align-items-center py-1">
						<div className="d-flex align-items-center py-2">
							<Image	src={"/assets/icons/whats-app-button-black-icon.svg"} width={20} height={20} alt="electric"/>
							<Typography variant="bodyLg" semanticTag="span" className="ms-3">{t("We in WhatsApp")}</Typography>
						</div>
						<a href={"http://api.whatsapp.com/send?phone=41435051318"} rel="noreferrer" target="_blank" className="text-decoration-none px-2 py-1 rounded" style={{backgroundColor: "#EBEBEB", color: "#212529"}}>
							<Typography variant="bodyXSmBold" semanticTag="span">{t("text-us")}</Typography>
						</a>
					</div>
					<div className="border-bottom pb-4">
						<BookConsultation className="mt-3 ps-0"/>
					</div>
				</div>

				<div className="d-flex flex-column pt-2 mt-1 pb-4 px-3">
					<div className="d-flex justify-content-between align-items-center cursor-pointer">
						<div className="d-flex align-items-center py-2" onClick={() => setIsInfocenterMenuVisible(true)}>
							<Image	src={"/assets/icons/question.svg"} width={20} height={20} alt="Resources"/>
							<Typography variant="bodyLg" semanticTag="span" className="ms-3">{t("Resources")}</Typography>
						</div>
						<Image	src={"/assets/icons/chevron-right.svg"} width={20} height={20} alt="chevron right"/>
					</div>
					<Link href={t("/kundendienst")}>
						<div className="d-flex align-items-center py-2 cursor-pointer">
							<Image	src={"/assets/icons/support.svg"} width={20} height={20} alt="support"/>
							<Typography variant="bodyLg" semanticTag="span" className="ms-3">{t("Kundendienst")}</Typography>
						</div>
					</Link>
					<Link href={t("/faq/konzept")}>
						<div className="d-flex align-items-center py-2 cursor-pointer">
							<Image	src={"/assets/icons/lights.svg"} width={20} height={20} alt="concept"/>
							<Typography variant="bodyLg" semanticTag="span" className="ms-3">{t("concept")}</Typography>
						</div>
					</Link>
					<MobileEcosystemTabs />
				</div>
			</nav>
		</div>
	)
}

export default NewMobileMegaMenu

NewMobileMegaMenu.defaultProps = {
	secondLineMenu: [
		{
			name: "Resources", items: [
				{name: "Blog", href: "/blog"},
				{name: "concept", href: "/konzept"},
				{name: "FAQ", href: "/faq"},
				{name: "Über uns", href: "/ueber-uns"}
			]
		}
	]
}


import React, {useState} from "react"
import SearchResultsDesktop from "../../search/SearchResults"
import {MenuTitlesArray} from "../../../utility/Helper"
import DesktopTopHeader from "./DesktopTopHeader"
import DesktopMidHeader from "./DesktopMidHeader"
import DesktopBottomHeader from "./DesktopBottomHeader"
import {useRouter} from "next/router"
import EcoSystemTabs from "./EcoSystemTabs"
import StickyBar from "../StickyBar"

type Props = {
	absolute: boolean;
	menuTitles: MenuTitlesArray[];
	dropdownData?: {
		label: string;
		items: { itemLabel: string; href: string }[];
	}[];
	hideNavBar:boolean
}

const DesktopNavbar: React.FC<Props> = ({
	absolute,
	menuTitles,
	dropdownData,
	hideNavBar = false
}) => {
	const [isToggleOpen, setIsToggleOpen] = useState(false)
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	const [isBackgroundOn, setIsBackgroundOn] = useState(false)
	const router = useRouter()
	const closeMegaMenu = () => {
		setIsToggleOpen(false)
	}

	return (
		<div className={`sticky-top w-100 ${hideNavBar ? "slide-up-off-screen-22 " : "slide-down-from-top-22 top-2 end-0 start-0"} `} style={{zIndex: 9999}}>
			<div style={{zIndex: 10000, borderBottomLeftRadius: "32px",
				borderBottomRightRadius: "32px"}} className={"d-none d-lg-block bg-white w-100 shadow-sm position-relative"}>
				<EcoSystemTabs />
				<div style={{maxWidth: 1790, margin: "0 auto"}}>
					<nav className={"bg-white position-sticky top-0 end-0 start-0 w-100"} style={{zIndex: absolute && !isToggleOpen && !isBackgroundOn ? 999 : 99999, borderBottomLeftRadius: "32px", borderBottomRightRadius: "32px", padding: "0px 72px"}}>
						<DesktopMidHeader setIsSidebarOpen={setIsSidebarOpen} isFrontPage={absolute && !isToggleOpen && !isBackgroundOn}/>
						<div className="d-flex justify-content-center align-items-start w-100 flex-wrap">
							<DesktopBottomHeader
								closeMegaMenu={closeMegaMenu}
								isBackgroundOn={isBackgroundOn}
								setIsBackgroundOn={setIsBackgroundOn}
								isToggleOpen={isToggleOpen}
								setIsToggleOpen={setIsToggleOpen}
							/>
							<DesktopTopHeader dropdownData={dropdownData}/>
						</div>
					</nav>
				</div>
			</div>
			<div className="d-none d-lg-block position-relative " style={{marginTop: -20, zIndex: -1}}>
				<StickyBar hasDiscountForNewsletterModal style={{borderBottomLeftRadius: 30, borderBottomRightRadius: 30, height: 65, justifyContent: "center", zIndex: -1}}/>
			</div>
			<div className="d-none d-md-block justify-content-center bg-white">
				<div style={{minWidth: "650px"}} className="row justify-content-center">
					<div className="col-12 col-lg-8">
						<SearchResultsDesktop/>
					</div>
				</div>
			</div>
		</div>
	)
}

DesktopNavbar.defaultProps = {
	dropdownData: [
		{
			label: "Resources",
			items: [
				{itemLabel: "Blog", href: "/blog"},
				{itemLabel: "FAQ", href: "/faq"}
			]
		}
	]
}
export default DesktopNavbar

/* eslint-disable camelcase */
import axios from "axios"
import {Field, Form, Formik} from "formik"
import {useRouter} from "next/router"
import React, {useState} from "react"
import Button from "../reusable/components/Button"
import FailMessage from "../reusable/FailMessage"
import FormInput from "../reusable/FormInput/FormInput"
import FormSelect from "../reusable/FormSelect/FormSelect"
import SuccessMessage from "../reusable/SuccessMessage"
import Typography from "../reusable/Typography/Typography"
import UploadField from "../reusable/UploadField"
import CheckBoxField from "./fields/CheckBoxField"
import {DatepickerInputs} from "./fields/DatePickerField"
import FormTextArea from "./fields/FormTextArea"
import PhoneField from "./fields/PhoneField"
// Import {fileKeys, uploadImageHandler} from "../../utility/aws/uploadIdToAWSHelper"
// import FormLabel from "../reusable/FormInput/FormLabel"
// import FormLabelUploadSmall from "../reusable/FormInput/FormLabelUploadSmall"
import {fileKeys, uploadImageHandler} from "../../utility/aws/uploadIdToAWSHelper"
import FormLabel from "../reusable/FormInput/FormLabel"
import FormLabelUploadSmall from "../reusable/FormInput/FormLabelUploadSmall"
import Image from "next/image"
import {fbEvents} from "../../config/facebook/events"

interface FormData {
    fields?: any[]
    validationSchema?: any
    initialValues?: any
	config?: any
	displayHelper?: (values:any, fields:any[]) => any[]
	routeSwitch?: string
	className?: string
	title?: string
	type?: string
	newInstance?: boolean
}

interface Props {
    formData: FormData
	header?: boolean
	hubspotConfig?: any
	t?: (t:string) => string
	theme?:string
}

const DynamicFormGenerator:React.FC<Props> = ({formData, header, t, theme}) => {
	const router = useRouter()
	const {pathname, locale} = router
	const pageUri = `${process.env.NEXT_PUBLIC_APP_URL}${pathname}`

	const {initialValues, validationSchema, fields, config, routeSwitch, className, title} = formData
	const hubspotConfig = config && config
	const targetPath = routeSwitch && routeSwitch
	const [fomSuccessfulSubmited, setFormSuccessfulSubmited] = useState(false)
	const [success, setSuccess] = useState(false)
	const [fieldsData, setFieldsData] = useState(fields)

	const retryHandler = (isRetrying:boolean|any) => {
		if (isRetrying) {
			setFormSuccessfulSubmited(false)
			setSuccess(false)
		}
	}

	const displayHandler = (values:any) => {
		if (formData.displayHelper) {
			setFieldsData(formData.displayHelper(values, fields))
		}
	}

	const isNewInstance = formData.newInstance

	return (
		<>
			{/* {header && <KundendienstHeader subTitle={"What do you want to ask about?"} back />} */}
			{fomSuccessfulSubmited ? success ? <SuccessMessage text={isNewInstance ? "Vielen Dank für Dein Interesse! Wir melden uns bei Dir, sobald der neue Shop online geht." : `${t("Vielen Dank für deine Nachricht! Wir werden uns umgehend bei dir melden und können es nicht erwarten, dich beim Übergang zur Zweirad-E-Mobilität zu unterstützen!")} 🚴😎`}/> :
				<FailMessage retryHandler={retryHandler} /> :
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnMount={true}
					onSubmit={async (values, {setSubmitting}) => {
						if (hubspotConfig) {
							try {
								if (hubspotConfig.oploadFile) {
									const {firstname, lastname} = values
									const tag = `${firstname}_${lastname}`
									await uploadImageHandler({
										fileFront: values.picture_of_display,
										fileRight: values.picture_right_side,
										fileLeft: values.picture_left_side
									}, tag).then(res => {
										res.forEach((item:any) => {
											delete values[fileKeys[item.side]]
											values.ticket_hs_file_upload = values.ticket_hs_file_upload ? values.ticket_hs_file_upload.concat(`, ${item.side}: ${item.link}`) : `${item.side}: ${item.link}`
										})
									})
								}

								const response = await axios.request({
									method: "post",
									baseURL: process.env.NEXT_PUBLIC_APP_URL,
									url: "/api/hubspot/sendFormDataToHubspot",
									data: {
										data: values,
										...hubspotConfig,
										instanceId: hubspotConfig.instanceId[locale.toLowerCase()],
										context: {
										// Hutk: ":hutk", // Include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
											pageUri
										// PageName: "Example page"
										}
									}
								})

								if (response.status === 200 || response.status === 201) {
									if (formData.type === "newCustomer" && typeof window !== "undefined" && window.fbq) {
										window.fbq("track", fbEvents.lead)
									}

									setFormSuccessfulSubmited(true)
									setSubmitting(false)
									setSuccess(true)
								}
							} catch (e) {
								setFormSuccessfulSubmited(true)
								setSubmitting(false)
								setSuccess(false)
							}
						}

						if (targetPath) {
							router.push(`${router.asPath}${targetPath}`)
							// SetFormSuccessfulSubmited(true)
							setSubmitting(false)
						}
					}}
				>
					{({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid}) => (
						<>
							{/* {console.log("values", values, errors)} */}
							{displayHandler(values)}
							<Form onSubmit={handleSubmit}>
								<div className={`${className ? className : "row mb-5 mt-4"}`}>

									{fieldsData.map((field, index) => {
										const {name, type, label, className, options, display} = field
										if (display) {
											return type === "text" ? <div key={`${label}-${name}-${index}`} className={className}>
												{ process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" && (name === "ticket_e_bike_model" || name === "ticket_mileage" || name === "ticket_requested_exchange_bike") ? null :
													<FormInput className="pb-3" errors={errors} touched={touched} name={name} label={t(label)} required={process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan"} errColor="dark" checkout />}
											</div> :
												type === "textarea" ? <div key={`${label}-${name}-${index}`} className={className}>
													<FormTextArea label={t(label)} name={name} required/>
												</div> :
													type === "select" ? <div key={`${label}-${name}-${index}`} className={className}>
														{ process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" && (name === "brand" || name === "ticket_requested_bike_brand") ? null :
															<Field outLabel name={name} label={t(label)} required={process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan"}
																component={FormSelect} t={t} options={options}/>}
													</div> :
														type === "custom" ? <>
															{field.component(label, name, `${label}-${name}-${index}`)}
														</> :
															type === "checkbox" ? <div key={`${label}-${name}-${index}`} className={className}>
																<CheckBoxField name={name}>
																	<Typography variant="bodyLg">{t(label)}</Typography>
																</CheckBoxField>
															</div> :
																type === "date" ? <div className="col-12 col-md-5 col-lg-5">
																	<DatepickerInputs name={name} label={t(label)} />
																</div> :
																	type === "file" ? <>

																		<UploadField name={name} type="file" id={name}
																			labeltext={t(label)}/>
																		{errors[name] && touched[name] ? (
																			<div style={{color: "red"}}>{errors[name]}</div>
																		) : null}
																	</> :
																		type === "phone" ? <PhoneField classNameContainer={className} key={`${label}-${name}-${index}`}/> :
																			type === "label" ? <div key={`${label}-${name}-${index}`} className={className}>
																				<FormLabel name={name} label={t(label)} required />
																			</div> :
																				type === "smallUploadLabel" ? <div key={`${label}-${name}-${index}`} className={className}>
																					<FormLabelUploadSmall label={t(label)} />
																				</div> :
																					type === "phone" ? <div key={`${label}-${name}-${index}`}>
																						<PhoneField classNameContainer={className}/>
																					</div> :
																						null
										}

										return null
									})}

									<div className="col-12">
										<Button
											icon={<Image src={"/assets/icons/iconCategories/mail-add-varient.svg"} width="24px" height="24px" alt="Mail-add" />}
											className="w-100"
											label={title === "disclaimerCheckBoxFormData" ? t("next") : t("Absenden")}
											color={"white"}
											type={"submit"}
											bgColor={theme ? theme : "black"}
											textColor={theme ? "black" : "white"}
											disabled={!isValid}
											isLoading={isSubmitting}
											spinnerColor={"white"}
										/>
									</div>

								</div>

							</Form>
						</>

					)}
				</Formik>
			}
		</>
	)
}

export default DynamicFormGenerator

